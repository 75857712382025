export const PAGE_TYPE = {
  HOMEPAGE: "ph_homepage",
  PROFILE: "ph_profile",
  CONTEST: "ph_contest",
};

export const CONTEST_TYPE = {
  PRESIDENT: "president",
  VICEPRESIDENT: "vpresident",
  SENATOR: "senator",
};

export const AD_UNIT = {
  MOBILE: {
    BILLBOARD: {
      NAME: "PHVote/PHVote_Top_Billboard",
      SIZE: [[320, 50]],
    },
    MIDDLE1: {
      NAME: "PHVote/PHVote_Middle_1",
      SIZE: [
        [300, 250],
        [1, 1],
        [336, 280],
      ],
    },
    MIDDLE2: {
      NAME: "PHVote/PHVote_Middle_2",
      SIZE: [
        [300, 250],
        [1, 1],
        [336, 280],
      ],
    },
    MIDDLE3: {
      NAME: "PHVote/PHVote_Middle_3",
      SIZE: [
        [300, 250],
        [1, 1],
        [336, 280],
      ],
    },
    LEADERBOARD1: {
      NAME: "PHVote/PHVote_LeaderBoard_1",
      SIZE: [[320, 50]],
    },
    LEADERBOARD2: {
      NAME: "PHVote/PHVote_LeaderBoard_2",
      SIZE: [[320, 50]],
    },
    STICKY: {
      NAME: "PHVote/PHVote_Sticky_Leaderboard",
      SIZE: [[320, 50]],
    },
    SKYSCRAPER: {
      NAME: "PHVote/PHVote_Skyscraper",
      SIZE: [
        [120, 600],
        [160, 600],
      ],
    },
    OOP: {
      NAME: "PHVote/OOP",
      SIZE: [[1, 1]],
    },
  },
  DESKTOP: {
    BILLBOARD: {
      NAME: "PHVote/PHVote_Top_Billboard",
      SIZE: [
        [970, 250],
        [970, 90],
        [728, 90],
      ],
    },
    MIDDLE1: {
      NAME: "PHVote/PHVote_Middle_1",
      SIZE: [
        [300, 250],
        [1, 1],
        [336, 280],
      ],
    },
    MIDDLE2: {
      NAME: "PHVote/PHVote_Middle_2",
      SIZE: [
        [300, 250],
        [1, 1],
        [336, 280],
      ],
    },
    MIDDLE3: {
      NAME: "PHVote/PHVote_Middle_3",
      SIZE: [
        [300, 250],
        [1, 1],
        [336, 280],
      ],
    },
    LEADERBOARD1: {
      NAME: "PHVote/PHVote_LeaderBoard_1",
      SIZE: [
        [970, 90],
        [728, 90],
      ],
    },
    LEADERBOARD2: {
      NAME: "PHVote/PHVote_LeaderBoard_2",
      SIZE: [
        [970, 90],
        [728, 90],
      ],
    },
    STICKY: {
      NAME: "PHVote/PHVote_Sticky_Leaderboard",
      SIZE: [
        [970, 90],
        [728, 90],
      ],
    },
    SKYSCRAPER: {
      NAME: "PHVote/PHVote_Skyscraper",
      SIZE: [
        [120, 600],
        [160, 600],
      ],
    },
    OOP: {
      NAME: "PHVote/OOP",
      SIZE: [[1, 1]],
    },
  },
};
