import Image from "next/image";
import arrowPrev from "../../public/images/icons/arrow-prev.png";
import arrowNext from "../../public/images/icons/arrow-next.png";

const loader = ({ src, width, quality }) => {
  const imageUrl = process.env.SITE_URL;
  return `${imageUrl}${src}?w=${width}&q=${quality || 75}`;
};

const NextArrow = ({ className, onClick }) => {
  return (
    <button aria-hidden="true" className={className} onClick={onClick}>
      <Image
        loader={loader}
        src={arrowNext}
        alt="next"
        width={20}
        height={20}
      />
    </button>
  );
};

const PrevArrow = ({ className, onClick }) => {
  return (
    <button aria-hidden="true" className={className} onClick={onClick}>
      <Image
        loader={loader}
        src={arrowPrev}
        alt="prev"
        width={20}
        height={20}
      />
    </button>
  );
};

export { NextArrow, PrevArrow };
