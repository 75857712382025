import { useState } from "react";
import { AD_UNIT } from "../../helpers/adsConstant";
import { useGtagSlot, useGtagOopSlot } from "../../hooks/useGtagSlot";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../styles/ads/Ads.module.scss";

export const Ads = ({ id, adType, deviceType, pageType, contestName }) => {
  useGtagSlot({
    id,
    deviceType,
    pageType,
    contestName,
    path: AD_UNIT[deviceType.toUpperCase()][adType.toUpperCase()].NAME,
    size: AD_UNIT[deviceType.toUpperCase()][adType.toUpperCase()].SIZE,
  });

  return (
    <div
      id={id}
      className={`${styles.ads} ${styles[deviceType]} ${styles[adType]}`}
    ></div>
  );
};

export const StickyAds = ({ id, deviceType, pageType }) => {
  const slot = useGtagSlot({
    id,
    deviceType,
    pageType,
    path: AD_UNIT[deviceType.toUpperCase()].STICKY.NAME,
    size: AD_UNIT[deviceType.toUpperCase()].STICKY.SIZE,
  });

  const [isStickyClosed, setIsStickyClosed] = useState(false);

  const closeSticky = () => {
    setIsStickyClosed(true);
  };

  return (
    <div
      className={`${styles.ads} ${styles[deviceType]} ${styles.sticky} ${
        isStickyClosed ? styles.closed : ""
      }`}
    >
      {!slot.isEmpty && (
        <div className={styles.stickyCloseButton}>
          <FontAwesomeIcon
            className={styles.stickyCloseIcon}
            icon={faTimes}
            onClick={closeSticky}
          />
        </div>
      )}
      <div id={id}></div>
    </div>
  );
};

export const OopAds = ({ path, id, targeting = [] }) => {
  useGtagOopSlot({ path, id, targeting });
  return <div id={id}></div>;
};
