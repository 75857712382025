import Head from "next/head";
import { useRouter } from "next/router";

const basePath = process.env.SITE_URL;
const SeoTag = ({
  title,
  description,
  keywords,
  og_image,
  twitter_og_image,
  twitter_title,
  twitter_description,
}) => {
  let router = useRouter();
  let url = `${basePath}${router.asPath.substring(1)}`;
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />
      <link
        rel="icon"
        href="https://www.rappler.com/content/themes/rappler/src/images/logo.svg"
      />
      <link
        rel="apple-touch-icon"
        href="https://www.rappler.com/content/themes/rappler/src/images/logo.svg"
      />
      {/*OPEN GRAPH META DATA*/}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={og_image} />
      <meta property="og:image:alt" content={title} />
      {/* TWITTER CARDS */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={twitter_og_image} />
      <meta name="twitter:title" content={twitter_title} />
      <meta name="twitter:description" content={twitter_description} />
      <meta name="twitter:site" content="@phrapplerdotcom" />
    </Head>
  );
};

const CxenseParseTag = ({ title }) => (
  <Head>
    <meta name="cXenseParse:title" content={title} />
    <meta name="cXenseParse:pageclass" content="frontpage" />
    <meta name="cXenseParse:rap-pageclass" content="phvote" />
  </Head>
);

export { SeoTag, CxenseParseTag };
