import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { CONTEST_TYPE } from "../helpers/adsConstant";

export const useGtagSlot = ({
  path,
  size,
  id,
  deviceType,
  pageType,
  contestName,
}) => {
  const pubId = process.env.ADS_ID;
  const slot = useRef(null);
  const mediaQuery = useRef(null);

  const position = useMemo(
    () => ({
      "PRESIDENT PHILIPPINES": CONTEST_TYPE.PRESIDENT,
      "VICE PRESIDENT PHILIPPINES": CONTEST_TYPE.VICEPRESIDENT,
      "SENATOR PHILIPPINES": CONTEST_TYPE.SENATOR,
    }),
    []
  );

  // Update targeting params here.
  const targeting = useMemo(
    () => [
      ["page_type", pageType],
      ["device", deviceType],
      ...(position[contestName] ? [["position", position[contestName]]] : []),
    ],
    [contestName, deviceType, pageType, position]
  );

  const createSlot = useCallback(() => {
    googletag.cmd.push(() => {
      slot.current = googletag.defineSlot(`${pubId}/${path}`, size, id);

      targeting.forEach((i) => {
        slot.current.setTargeting(i[0], i[1]);
      });

      slot.current.setCollapseEmptyDiv(true);
      slot.current.addService(googletag.pubads());

      googletag.display(slot.current);
    });
  }, [id, path, pubId, size, targeting]);

  const destroySlot = useCallback(() => {
    googletag.cmd.push(() => {
      googletag.destroySlots([slot.current]);
    });
  }, []);

  const handleDeviceResize = useCallback(
    (e) => {
      // Only display ads wrt device width.
      if (
        (!e.matches && deviceType === "desktop") ||
        (e.matches && deviceType === "mobile")
      ) {
        destroySlot();
      } else {
        createSlot();
      }
    },
    [deviceType, createSlot, destroySlot]
  );

  const startMediaQueryListener = useCallback(() => {
    mediaQuery.current = window.matchMedia("(min-width: 1024px)");

    handleDeviceResize(mediaQuery.current);
    mediaQuery.current.addEventListener("change", handleDeviceResize);
  }, [handleDeviceResize]);

  const removeMediaQueryListener = useCallback(() => {
    mediaQuery.current.removeEventListener("change", handleDeviceResize);
  }, [handleDeviceResize]);

  const [isSlotEmpty, setIsSlotEmpty] = useState(true);

  const handleSlotRenderEnded = useCallback((e) => {
    if (slot.current !== e.slot) {
      return;
    }

    setIsSlotEmpty(e.isEmpty);
  }, []);

  const startSlotRenderListener = useCallback(() => {
    googletag.cmd.push(() => {
      googletag
        .pubads()
        .addEventListener("slotRenderEnded", handleSlotRenderEnded);
    });
  }, [handleSlotRenderEnded]);

  const removeSlotRenderListener = useCallback(() => {
    googletag.cmd.push(() => {
      googletag
        .pubads()
        .removeEventListener("slotRenderEnded", handleSlotRenderEnded);
    });
  }, [handleSlotRenderEnded]);

  useEffect(() => {
    startSlotRenderListener();
    startMediaQueryListener();

    return () => {
      destroySlot();
      removeSlotRenderListener();
      removeMediaQueryListener();
    };
  }, [
    id,
    destroySlot,
    startMediaQueryListener,
    removeMediaQueryListener,
    startSlotRenderListener,
    removeSlotRenderListener,
  ]);

  return {
    isEmpty: isSlotEmpty,
  };
};

export const useGtagOopSlot = ({ path, id, targeting = [] }) => {
  let isMounted = true;
  const pubId = process.env.ADS_ID;

  useEffect(() => {
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      googletag
        .defineOutOfPageSlot(`${pubId}/${path}`, id)
        .addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();

      targeting.forEach((i) => {
        googletag.pubads().setTargeting(i.key, i.value);
      });
      googletag.enableServices();
    });

    googletag.cmd.push(() => {
      googletag.display(id);
    });

    return () => {
      isMounted = false;
    };
  }, []);
  return false;
};
